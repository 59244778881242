import React from 'react';
import { Heading, Text, Container } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const Authentication = () => {
  return (
    <Container maxW="container.xl" py={10}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Heading as="h1" size="2xl" mb={4}>
          Authentication
        </Heading>
        <Text fontSize="xl">
          Learn how to authenticate your requests to the Maximo AI PREMIUM API.
        </Text>
      </motion.div>
    </Container>
  );
};

export default Authentication;