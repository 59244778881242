import React from 'react';
import {
  Box, Flex, Heading, Text, Container, VStack, Button, useColorModeValue, Image, keyframes,
  Stack, IconButton, Link as ChakraLink
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import maximoAiLogo from '../images/maximo-ai-logo-circle.png';
import { FaInstagram, FaTwitter, FaTelegramPlane } from 'react-icons/fa';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 128, 128, 0.7); }
  70% { box-shadow: 0 0 0 20px rgba(0, 128, 128, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 128, 128, 0); }
`;

const logoPulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const MotionImage = motion(Image);

const AnimatedLogo = () => (
  <Box
    as={motion.div}
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    position="relative"
    width={{ base: "200px", md: "300px" }}
    height={{ base: "200px", md: "300px" }}
  >
    <Image
      src={maximoAiLogo}
      alt="Maximo AI Logo"
      width="100%"
      height="100%"
      objectFit="cover"
      as={motion.img}
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 20,
        repeat: Infinity,
        ease: "linear"
      }}
      borderRadius="full"
      zIndex={2}
      animation={`${logoPulseAnimation} 2s infinite`}
    />
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      borderRadius="full"
      animation={`${pulseAnimation} 2s infinite`}
      zIndex={1}
    />
  </Box>
);

const FeatureCard = ({ title, description, icon }) => (
  <Box
    as={motion.div}
    whileHover={{ scale: 1.05 }}
    p={6}
    bg={useColorModeValue('white', 'gray.700')}
    borderRadius="xl"
    boxShadow="xl"
    textAlign="center"
  >
    <Box color="teal.500" fontSize="3xl" mb={4}>
      {icon}
    </Box>
    <Heading as="h3" size="md" mb={2}>
      {title}
    </Heading>
    <Text>{description}</Text>
  </Box>
);

const Home = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  return (
    <Box bg={bgColor} minH="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={16} align="stretch">
          <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
            <Box flex={1} mr={{ base: 0, md: 8 }} mb={{ base: 8, md: 0 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Heading as="h1" size="2xl" mb={4} color={textColor}>
                  Elevate Your Business with Maximo AI
                </Heading>
                <Text fontSize="xl" mb={8} color={textColor}>
                  Discover the future of digital strategy with AI-powered trading, content creation, and automation tools.
                </Text>
                <Button
                  as={RouterLink}
                  to="/endpoints"
                  size="lg"
                  colorScheme="teal"
                  fontWeight="bold"
                  borderRadius="full"
                  px={8}
                  _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                >
                  Explore Our API
                </Button>
              </motion.div>
            </Box>
            <Box flex={1} maxW={{ base: '100%', md: '400px' }} display="flex" justifyContent="center">
              <AnimatedLogo />
            </Box>
          </Flex>

          <VStack spacing={8}>
            <Heading as="h2" size="xl" textAlign="center" color={textColor}>
              Our Services
            </Heading>
            <Flex direction={{ base: 'column', md: 'row' }} gap={8} w="100%">
              <FeatureCard
                title="AI-Powered Trading"
                description="Leverage advanced algorithms for smarter, data-driven trading decisions."
                icon="💹"
              />
              <FeatureCard
                title="Content Creation"
                description="Generate high-quality, engaging content with our AI-powered tools."
                icon="✍️"
              />
              <FeatureCard
                title="Automation Tools"
                description="Streamline your workflows and boost productivity with intelligent automation."
                icon="🤖"
              />
            </Flex>
          </VStack>

          <Box textAlign="center">
            <Heading as="h2" size="xl" mb={4} color={textColor}>
              Ready to Transform Your Business?
            </Heading>
            <Button
              as={RouterLink}
              to="/endpoints"
              size="lg"
              colorScheme="teal"
              fontWeight="bold"
              borderRadius="full"
              px={8}
              _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
            >
              Get Started Now
            </Button>
          </Box>
        </VStack>
      </Container>
      <Box bg={bgColor} py={8} mt={20}>
        <Container maxW="container.xl">
          <Flex direction={{ base: "column", md: "row" }} align="center" justify="space-between">
            <Flex align="center">
              <MotionImage
                src={maximoAiLogo}
                boxSize="40px"
                alt="Maximo AI Logo"
                mr={4}
                whileHover={{ rotate: 360 }}
                transition={{ duration: 1 }}
              />
              <Text fontSize="lg" fontWeight="bold" color={textColor} fontFamily="Lexend">
                Maximo AI
              </Text>
            </Flex>
            <Stack direction="row" spacing={4} mt={{ base: 4, md: 0 }}>
              <ChakraLink href="https://x.com/AskMaximoAI" isExternal>
                <IconButton
                  as="a"
                  icon={<FaTwitter />}
                  aria-label="Twitter"
                  size="lg"
                  colorScheme="twitter"
                  variant="ghost"
                />
              </ChakraLink>
              <ChakraLink href="https://t.me/maximoai" isExternal>
                <IconButton
                  as="a"
                  icon={<FaTelegramPlane />}
                  aria-label="Telegram"
                  size="lg"
                  colorScheme="telegram"
                  variant="ghost"
                />
              </ChakraLink>
              <ChakraLink href="https://instagram.com/maximoai_co" isExternal>
                <IconButton
                  as="a"
                  icon={<FaInstagram />}
                  aria-label="Instagram"
                  size="lg"
                  colorScheme="pink"
                  variant="ghost"
                />
              </ChakraLink>
            </Stack>
          </Flex>
          <Text mt={4} fontSize="sm" color={textColor} textAlign={{ base: 'center', md: 'left' }} fontFamily="Lexend">
            &copy; {new Date().getFullYear()} Maximo AI. All rights reserved.
          </Text>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;