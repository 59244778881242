import React, { useState } from 'react';
import { Box, Heading, Text, Container, VStack, useColorModeValue, Input, Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Endpoint from '../components/Endpoint';

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const ApiDocumentation = () => {
  const bgColor = useColorModeValue('gray.800', 'gray.900');
  const textColor = useColorModeValue('gray.100', 'gray.50');
  const bgColor2 = useColorModeValue('gray.700', 'gray.800');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const endpoints = [
    {
      title: "1. Get Pairs with the Lowest Risk Percentages",
      method: "GET",
      url: "/api-key-trending",
      description: "This endpoint retrieves the top 5 trending cryptocurrency pairs with the lowest risk percentages using the provided API key. The user's points will be charged for the request.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api-key-trending \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api-key-trending',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "1h": [
    {
      "pair": "BTC/USDT",
      "riskPercentage": "0.02",
      "riskLevel": "Low",
      "message": "Safe to invest"
    },
    {
      "pair": "ETH/USDT",
      "riskPercentage": "0.03",
      "riskLevel": "Low",
      "message": "Safe to invest"
    }
  ]
}`
    },
    {
      title: "2. Analyze Market Pairs",
      method: "POST",
      url: "/api-key-analysis",
      description: "This endpoint analyzes 3 to 4 pairs based on the pair category for sentiment, and the user's requested pair across multiple exchanges. It provides trading suggestions, including stop loss, take profit, leverage suggestions, and risk levels.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api-key-analysis \\
-H "x-api-key: your-api-key-here" \\
-H "Content-Type: application/json" \\
-d '{
  "userPair": "DOGE/USDT",
  "pairCategoryType": "type1",
  "timeframe": "1h",
  "language": "en"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  userPair: 'DOGE/USDT',
  pairCategoryType: 'type1',
  timeframe: '1h',
  language: 'en'
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api-key-analysis',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "MAXIMO suggests opening a long position.",
  "userPair": {
    "pair": "DOGE/USDT",
    "recommendation": "BUY",
    "currentPrice": "0.123456",
    "high24h": "0.130000",
    "low24h": "0.120000",
    "stopLossPercentage": "0.50",
    "takeProfitPercentage": "1.00",
    "leverage": "20.00",
    "stopLossPrice": "0.122000",
    "takeProfitPrice": "0.126000",
    "riskLevel": "medium",
    "riskPercentage": "60.00"
  },
  "recommendations": {
    "BTC": "BUY",
    "BNB": "HOLD",
    "ETH": "SELL",
    "SOL": "BUY"
  }
}`
    },
    {
      title: "3. Ask Maximo a Question (1b)",
      method: "POST",
      url: "/api-key-ask-maximo-1b",
      description: "This endpoint allows users to ask Maximo AI questions, analyze cryptocurrency pairs, generate images, browse and summarize the web, get what coins to buy or sell, and set alerts for specific pairs. It supports file and image uploads and integrates with multiple advanced open and closed-sourced LLMs for enhanced responses.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api-key-ask-maximo-1b \\
-H "x-api-key: your-api-key-here" \\
-H "Content-Type: multipart/form-data" \\
-F "question=What coins should I buy?" \\
-F "language=en"`,
      nodeExample: `const axios = require('axios');
const FormData = require('form-data');

let data = new FormData();
data.append('question', 'What coins should I buy?');
data.append('language', 'en');

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api-key-ask-maximo-1b',
  headers: { 
    'x-api-key': 'your-api-key-here',
    ...data.getHeaders()
  },
  data : data
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Based on the analysis, here are the top coins to buy: 1. BTC/USDT 2. ETH/USDT 3. DOGE/USDT",
  "recommendations": [
    {
      "pair": "BTC/USDT",
      "recommendation": "BUY",
      "rationale": "Strong market trends and positive indicators."
    },
    {
      "pair": "ETH/USDT",
      "recommendation": "BUY",
      "rationale": "Consistent growth and high trading volume."
    },
    {
      "pair": "DOGE/USDT",
      "recommendation": "BUY",
      "rationale": "Increasing social media interest and positive sentiment."
    }
  ]
}`
    },
    {
      title: "4. Generate a High-Quality Prompt",
      method: "POST",
      url: "/api-generate-prompt",
      description: "This endpoint allows users to generate a high-quality, sophisticated prompt from a simple task description. It utilizes Maximo AI's advanced AI models to create detailed and elaborate prompts based on the provided task description.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api-generate-prompt \\
-H "x-api-key: your-api-key-here" \\
-H "Content-Type: application/json" \\
-d '{
  "taskDescription": "Write a detailed blog post about the latest trends in AI technology."
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  taskDescription: 'Write a detailed blog post about the latest trends in AI technology.'
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api-generate-prompt',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "highQualityPrompt": "As an expert in AI technology, craft a comprehensive and insightful blog post exploring the latest trends in AI. Discuss key developments in machine learning, deep learning, natural language processing, and computer vision. Highlight recent breakthroughs, emerging applications, and potential future directions. Ensure the content is well-researched, engaging, and informative, catering to both technical and non-technical audiences. Include real-world examples, case studies, and expert opinions to substantiate your points."
}`
    },
    {
      title: "5. Get API Request Statistics",
      method: "GET",
      url: "/api/request-stats",
      description: "This endpoint provides the total number of API requests made over various periods (all-time, last 24 hours, last 7 days, last 30 days). This data helps understand the usage and demand for the Maximo AI APIs.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api-requests/all-time
curl -X GET https://rad.huddlz.xyz/api/api-requests/last-24-hours
curl -X GET https://rad.huddlz.xyz/api/api-requests/last-7-days
curl -X GET https://rad.huddlz.xyz/api/api-requests/last-30-days`,
      nodeExample: `const axios = require('axios');

const endpoints = [
  '/api/api-requests/all-time',
  '/api/api-requests/last-24-hours',
  '/api/api-requests/last-7-days',
  '/api/api-requests/last-30-days'
];

endpoints.forEach(endpoint => {
  axios.get(\`https://rad.huddlz.xyz\${endpoint}\`)
    .then(response => console.log(\`\${endpoint}: \${JSON.stringify(response.data)}\`))
    .catch(error => console.log(\`Error for \${endpoint}: \${error}\`));
});`,
      responseExample: `{"totalRequests":3}{"totalRequests":3}{"totalRequests":3}{"totalRequests":3}`
    },
    {
      title: "6. Create a Document",
      method: "POST",
      url: "/api/create-document",
      description: "This endpoint creates a new document based on the provided parameters.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/create-document \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "documentType": "essay",
  "topic": "The Impact of Artificial Intelligence on Modern Society",
  "tone": "academic",
  "language": "English",
  "slides": 0,
  "moreDetails": "Focus on both positive and negative impacts, including ethical considerations."
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  documentType: "essay",
  topic: "The Impact of Artificial Intelligence on Modern Society",
  tone: "academic",
  language: "English",
  slides: 0,
  moreDetails: "Focus on both positive and negative impacts, including ethical considerations."
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/create-document',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Document created and saved successfully",
  "documentId": "12345-67890-abcde",
  "response": {
    "text": "The content of the generated document..."
  }
}`
    },
    {
      title: "7. Save a Document",
      method: "POST",
      url: "/api/save-document",
      description: "This endpoint saves changes to an existing document.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/save-document \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "documentId": "12345-67890-abcde",
  "content": "Updated content of the document...",
  "documentType": "essay",
  "topic": "The Impact of Artificial Intelligence on Modern Society",
  "tone": "academic",
  "language": "English",
  "slides": 0,
  "moreDetails": "Updated details about the document."
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  documentId: "12345-67890-abcde",
  content: "Updated content of the document...",
  documentType: "essay",
  topic: "The Impact of Artificial Intelligence on Modern Society",
  tone: "academic",
  language: "English",
  slides: 0,
  moreDetails: "Updated details about the document."
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/save-document',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Document saved successfully"
}`
    },
    {
      title: "8. Retrieve All Documents",
      method: "GET",
      url: "/api/documents",
      description: "This endpoint retrieves all documents associated with the user's API key.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/documents \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/documents',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
    "documents": [
      {
        "id": 1,
        "documentId": "12345-67890-abcde",
        "documentType": "essay",
        "topic": "The Impact of Artificial Intelligence on Modern Society",
        "created_at": "2023-07-28T12:34:56.789Z",
        "updated_at": "2023-07-28T12:34:56.789Z"
      },
      // More documents...
    ]
  }`
    },
    {
      title: "9. Retrieve a Specific Document",
      method: "GET",
      url: "/api/documents/:documentId",
      description: "This endpoint retrieves a specific document by its document ID.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/documents/12345-67890-abcde \\
  -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
  
  const config = {
    method: 'get',
    url: 'https://rad.huddlz.xyz/api/api/documents/12345-67890-abcde',
    headers: { 'x-api-key': 'your-api-key-here' }
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "document": {
      "id": 1,
      "documentId": "12345-67890-abcde",
      "documentType": "essay",
      "topic": "The Impact of Artificial Intelligence on Modern Society",
      "content": "The full content of the document...",
      "created_at": "2023-07-28T12:34:56.789Z",
      "updated_at": "2023-07-28T12:34:56.789Z"
    }
  }`
    },
    {
      title: "10. Delete a Document",
      method: "DELETE",
      url: "/api/documents/:documentId",
      description: "This endpoint deletes a specific document by its document ID.",
      curlExample: `curl -X DELETE https://rad.huddlz.xyz/api/api/documents/12345-67890-abcde \\
  -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
  
  const config = {
    method: 'delete',
    url: 'https://rad.huddlz.xyz/api/api/documents/12345-67890-abcde',
    headers: { 'x-api-key': 'your-api-key-here' }
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "message": "Document deleted successfully"
  }`
    },
    {
      title: "11. Create a PowerPoint Document",
      method: "POST",
      url: "/api/create-ppt-document",
      description: "This endpoint creates a new PowerPoint document based on the provided parameters.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/create-ppt-document \\
  -H "Content-Type: application/json" \\
  -H "x-api-key: your-api-key-here" \\
  -d '{
    "documentType": "presentation",
    "topic": "The Future of Artificial Intelligence",
    "tone": "professional",
    "language": "English",
    "slides": 10,
    "moreDetails": "Include sections on machine learning, natural language processing, and ethical considerations."
  }'`,
      nodeExample: `const axios = require('axios');
  
  const data = {
    documentType: "presentation",
    topic: "The Future of Artificial Intelligence",
    tone: "professional",
    language: "English",
    slides: 10,
    moreDetails: "Include sections on machine learning, natural language processing, and ethical considerations."
  };
  
  const config = {
    method: 'post',
    url: 'https://rad.huddlz.xyz/api/api/create-ppt-document',
    headers: { 
      'x-api-key': 'your-api-key-here',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "message": "Document created and saved successfully",
    "documentId": "67890-12345-fghij",
    "response": {
      "text": "The HTML content of the generated PowerPoint document..."
    }
  }`
    },
    {
      title: "12. Save a PowerPoint Document",
      method: "POST",
      url: "/api/save-ppt-document",
      description: "This endpoint saves changes to an existing PowerPoint document.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/save-ppt-document \\
  -H "Content-Type: application/json" \\
  -H "x-api-key: your-api-key-here" \\
  -d '{
    "documentId": "67890-12345-fghij",
    "content": "<html>Updated content of the presentation...</html>",
    "documentType": "presentation",
    "topic": "The Future of Artificial Intelligence: Updated",
    "tone": "professional",
    "language": "English",
    "slides": 12,
    "moreDetails": "Added new sections on robotics and computer vision."
  }'`,
      nodeExample: `const axios = require('axios');
  
  const data = {
    documentId: "67890-12345-fghij",
    content: "<html>Updated content of the presentation...</html>",
    documentType: "presentation",
    topic: "The Future of Artificial Intelligence: Updated",
    tone: "professional",
    language: "English",
    slides: 12,
    moreDetails: "Added new sections on robotics and computer vision."
  };
  
  const config = {
    method: 'post',
    url: 'https://rad.huddlz.xyz/api/api/save-ppt-document',
    headers: { 
      'x-api-key': 'your-api-key-here',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "message": "Document saved successfully"
  }`
    },
    {
      title: "13. Retrieve All PowerPoint Documents",
      method: "GET",
      url: "/api/ppt-documents",
      description: "This endpoint retrieves all PowerPoint documents associated with the user's API key.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/ppt-documents \\
  -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
  
  const config = {
    method: 'get',
    url: 'https://rad.huddlz.xyz/api/api/ppt-documents',
    headers: { 'x-api-key': 'your-api-key-here' }
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "documents": [
      {
        "id": 1,
        "documentId": "67890-12345-fghij",
        "documentType": "presentation",
        "topic": "The Future of Artificial Intelligence",
        "created_at": "2023-07-28T12:34:56.789Z",
        "updated_at": "2023-07-28T12:34:56.789Z"
      },
      // More documents...
    ]
  }`
    },
    {
      title: "14. Retrieve a Specific PowerPoint Document",
      method: "GET",
      url: "/api/ppt-documents/:documentId",
      description: "This endpoint retrieves a specific PowerPoint document by its document ID.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/ppt-documents/67890-12345-fghij \\
  -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
  
  const config = {
    method: 'get',
    url: 'https://rad.huddlz.xyz/api/api/ppt-documents/67890-12345-fghij',
    headers: { 'x-api-key': 'your-api-key-here' }
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "document": {
      "id": 1,
      "documentId": "67890-12345-fghij",
      "documentType": "presentation",
      "topic": "The Future of Artificial Intelligence",
      "content": "<html>The full content of the PowerPoint document...</html>",
      "created_at": "2023-07-28T12:34:56.789Z",
      "updated_at": "2023-07-28T12:34:56.789Z"
    }
  }`
    },
    {
      title: "15. Delete a PowerPoint Document",
      method: "DELETE",
      url: "/api/ppt-documents/:documentId",
      description: "This endpoint deletes a specific PowerPoint document by its document ID.",
      curlExample: `curl -X DELETE https://rad.huddlz.xyz/api/api/ppt-documents/67890-12345-fghij \\
  -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
  
  const config = {
    method: 'delete',
    url: 'https://rad.huddlz.xyz/api/api/ppt-documents/67890-12345-fghij',
    headers: { 'x-api-key': 'your-api-key-here' }
  };
  
  axios(config)
    .then(response => console.log(JSON.stringify(response.data)))
    .catch(error => console.log(error));`,
      responseExample: `{
    "message": "Document deleted successfully"
  }`
    },
    {
      title: "16. Create a Project",
      method: "POST",
      url: "/api/api/projects",
      description: "This endpoint creates a new project for the authenticated user.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/projects \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{"name": "My New Project"}'`,
      nodeExample: `const axios = require('axios');

const data = {
  name: "My New Project"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/projects',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Project created successfully",
  "projectId": "12345"
}`
    },
    {
      title: "17. Get All Projects",
      method: "GET",
      url: "/api/api/projects",
      description: "This endpoint retrieves all projects for the authenticated user.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/projects \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/projects',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `[
  {
    "id": 1,
    "name": "Project 1",
    "created_at": "2023-07-28T12:34:56.789Z"
  },
  {
    "id": 2,
    "name": "Project 2",
    "created_at": "2023-07-29T10:11:12.131Z"
  }
]`
    },
    {
      title: "18. Get a Specific Project",
      method: "GET",
      url: "/api/api/projects/:projectId",
      description: "This endpoint retrieves a specific project by its ID.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/projects/12345 \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "id": 12345,
  "name": "My Project",
  "created_at": "2023-07-28T12:34:56.789Z",
  "files": [
    {
      "id": 1,
      "name": "main.js",
      "content": "// Your code here"
    }
  ]
}`
    },
    {
      title: "19. Update a Project File",
      method: "PUT",
      url: "/api/api/projects/:projectId/files/:fileId",
      description: "This endpoint updates the content of a specific file in a project.",
      curlExample: `curl -X PUT https://rad.huddlz.xyz/api/api/projects/12345/files/1 \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{"content": "// Updated code here"}'`,
      nodeExample: `const axios = require('axios');

const data = {
  content: "// Updated code here"
};

const config = {
  method: 'put',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345/files/1',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "File updated successfully"
}`
    },
    {
      title: "20. Ask Maximo a Code Question (1m)",
      method: "POST",
      url: "/api/api/ask-maximo-code-1m",
      description: "This endpoint allows users to ask Maximo AI questions about code using the 1m model.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/ask-maximo-code-1m \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "prompt": "How do I create a React component?",
  "code": "// Current code here"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  prompt: "How do I create a React component?",
  code: "// Current code here"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/ask-maximo-code-1m',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "response": {
    "text": "To create a React component, you can follow these steps:\n\n1. Create a new JavaScript file with a .js or .jsx extension.\n2. Import React at the top of your file:\n   import React from 'react';\n3. Define your component as a function or class:\n   function MyComponent() {\n     return <div>Hello, World!</div>;\n   }\n4. Export your component:\n   export default MyComponent;\n\nHere's a complete example:\n\nimport React from 'react';\n\nfunction MyComponent() {\n  return (\n    <div>\n      <h1>Hello, World!</h1>\n      <p>This is my first React component!</p>\n    </div>\n  );\n}\n\nexport default MyComponent;\n\nYou can then use this component in other parts of your application by importing it and rendering it like this:\n\nimport MyComponent from './MyComponent';\n\nfunction App() {\n  return (\n    <div>\n      <MyComponent />\n    </div>\n  );\n}\n\nThis creates a simple React component that you can customize and expand upon."
  }
}`
    },
    {
      title: "21. Ask Maximo a Code Question (1b)",
      method: "POST",
      url: "/api/api/ask-maximo-code-1b",
      description: "This endpoint allows users to ask Maximo AI questions about code using the 1b model.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/ask-maximo-code-1b \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "prompt": "Explain this code",
  "code": "function example() { return 'Hello World'; }"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  prompt: "Explain this code",
  code: "function example() { return 'Hello World'; }"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/ask-maximo-code-1b',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "response": {
    "text": "This code defines a function named 'example'. When called, it will return the string 'Hello World'. It's a simple function that doesn't take any parameters and always returns the same greeting."
  }
}`
    },
    {
      title: "22. Ask Maximo Claude",
      method: "POST",
      url: "/api/api/ask-maximo-claude",
      description: "This endpoint allows users to ask questions to Maximo AI using the Claude model.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/ask-maximo-claude \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "prompt": "What are the best practices for writing clean code?",
  "code": "// Sample code here"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  prompt: "What are the best practices for writing clean code?",
  code: "// Sample code here"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/ask-maximo-claude',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "response": {
    "text": "Some best practices for writing clean code include:\n1. Use meaningful variable and function names\n2. Keep functions small and focused on a single task\n3. Avoid deep nesting\n4. Comment your code, but prefer self-explanatory code\n5. Follow consistent formatting and style guidelines\n6. Write modular and reusable code\n7. Avoid duplicating code (DRY principle)\n8. Handle errors and edge cases\n9. Write unit tests for your code\n10. Regularly refactor your code"
  }
}`
    },
    {
      title: "23. Ask Maximo Llama",
      method: "POST",
      url: "/api/api/ask-maximo-llama",
      description: "This endpoint allows users to ask questions to Maximo AI using the Llama model.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/ask-maximo-llama \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "prompt": "Optimize this code",
  "code": "function fibonacci(n) { if (n <= 1) return n; return fibonacci(n-1) + fibonacci(n-2); }"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  prompt: "Optimize this code",
  code: "function fibonacci(n) { if (n <= 1) return n; return fibonacci(n-1) + fibonacci(n-2); }"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/ask-maximo-llama',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "response": {
    "text": "Here's an optimized version of the Fibonacci function using dynamic programming:\n\nfunction fibonacci(n) {\n  const fib = [0, 1];\n  for (let i = 2; i <= n; i++) {\n    fib[i] = fib[i-1] + fib[i-2];\n  }\n  return fib[n];\n}\n\nThis version has a time complexity of O(n) instead of the original exponential time complexity."
  }
}`
    },
    {
      title: "24. Edit Project File Name",
      method: "PUT",
      url: "/api/api/projects/:projectId/files/:fileId/rename",
      description: "This endpoint allows users to rename a project file.",
      curlExample: `curl -X PUT https://rad.huddlz.xyz/api/api/projects/12345/files/1/rename \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{"newName": "updatedMain.js"}'`,
      nodeExample: `const axios = require('axios');

const data = {
  newName: "updatedMain.js"
};

const config = {
  method: 'put',
 url: 'https://rad.huddlz.xyz/api/api/projects/12345/files/1/rename',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "File name updated successfully"
}`
    },
    {
      title: "25. Delete Project File",
      method: "DELETE",
      url: "/api/api/projects/:projectId/files/:fileId",
      description: "This endpoint allows users to delete a project file.",
      curlExample: `curl -X DELETE https://rad.huddlz.xyz/api/api/projects/12345/files/1 \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'delete',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345/files/1',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "File deleted successfully"
}`
    },
    {
      title: "26. Create Project File",
      method: "POST",
      url: "/api/api/projects/:projectId/files",
      description: "This endpoint allows users to create a new file in a project.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/projects/12345/files \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "name": "newFile.js",
  "content": "// New file content"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  name: "newFile.js",
  content: "// New file content"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345/files',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "File created successfully",
  "fileId": "2"
}`
    },
    {
      title: "27. Get All Files for a Project",
      method: "GET",
      url: "/api/api/projects/:projectId/files",
      description: "This endpoint retrieves all files for a specific project.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/projects/12345/files \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345/files',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `[
  {
    "id": 1,
    "name": "main.js",
    "content": "// Main file content",
    "created_at": "2023-07-28T12:34:56.789Z",
    "updated_at": "2023-07-28T12:34:56.789Z"
  },
  {
    "id": 2,
    "name": "newFile.js",
    "content": "// New file content",
    "created_at": "2023-07-29T10:11:12.131Z",
    "updated_at": "2023-07-29T10:11:12.131Z"
  }
]`
    },
    {
      title: "28. Edit Project Name",
      method: "PUT",
      url: "/api/api/projects/:projectId",
      description: "This endpoint allows users to edit the name of a project.",
      curlExample: `curl -X PUT https://rad.huddlz.xyz/api/api/projects/12345 \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{"newName": "Updated Project Name"}'`,
      nodeExample: `const axios = require('axios');

const data = {
  newName: "Updated Project Name"
};

const config = {
  method: 'put',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Project name updated successfully"
}`
    },
    {
      title: "29. Delete Project",
      method: "DELETE",
      url: "/api/api/projects/:projectId",
      description: "This endpoint allows users to delete a project and all its associated files.",
      curlExample: `curl -X DELETE https://rad.huddlz.xyz/api/api/projects/12345 \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'delete',
  url: 'https://rad.huddlz.xyz/api/api/projects/12345',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Project deleted successfully"
}`
    },
    {
      title: "30. Run Code",
      method: "POST",
      url: "/api/api/run-code",
      description: "This endpoint allows users to run code in a virtual environment.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/run-code \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "code": "console.log('Hello, World!');",
  "language": "javascript"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  code: "console.log('Hello, World!');",
  language: "javascript"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/run-code',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Code execution completed",
  "output": "Hello, World!\n",
  "error": "",
  "exitCode": 0
}`
    },
    {
      title: "31. Ask Maximo a Question (1m)",
      method: "POST",
      url: "/api/api/ask-maximo-1m",
      description: "This endpoint allows users to ask Maximo AI questions, analyze cryptocurrency pairs, and get trading recommendations, using the 1m model.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/ask-maximo-1m \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "question": "What is the price of Bitcoin?",
  "language": "en"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  question: "What is the price of Bitcoin?",
  language: "en"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/ask-maximo-1m',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "The current price of Bitcoin (BTC) is $50,000. This represents a 2% increase over the last 24 hours. The trading volume has been steady, and market sentiment appears bullish due to recent institutional investments.",
  "pair": "BTC/USDT",
  "currentPrice": "50000",
  "recommendation": "BUY",
  "rationale": "Strong upward trend and positive market sentiment suggest potential for further growth."
}`
    },
    {
      title: "32. Get Chat Histories",
      method: "GET",
      url: "/api/api/chat-histories",
      description: "This endpoint retrieves all chat histories for the authenticated user.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/chat-histories \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/chat-histories',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "chatHistories": [
    {
      "session_id": "abc123",
      "title": "Bitcoin Analysis",
      "last_activity": "2023-07-28T12:34:56.789Z",
      "is_active": 1
    },
    {
      "session_id": "def456",
      "title": "Ethereum Predictions",
      "last_activity": "2023-07-29T10:11:12.131Z",
      "is_active": 1
    }
  ]
}`
    },
    {
      title: "33. Start or Continue Chat Session",
      method: "POST",
      url: "/api/api/start-or-continue-session",
      description: "This endpoint starts a new chat session or continues an existing one.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/start-or-continue-session \\
-H "Content-Type: application/json" \\
-H "x-api-key: your-api-key-here" \\
-d '{
  "title": "New Chat Session"
}'`,
      nodeExample: `const axios = require('axios');

const data = {
  title: "New Chat Session"
};

const config = {
  method: 'post',
  url: 'https://rad.huddlz.xyz/api/api/start-or-continue-session',
  headers: { 
    'x-api-key': 'your-api-key-here',
    'Content-Type': 'application/json'
  },
  data: JSON.stringify(data)
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Chat session started or continued",
  "sessionId": "ghi789",
  "title": "New Chat Session"
}`
    },
    {
      title: "34. Get Chat History",
      method: "GET",
      url: "/api/api/chat-history/:sessionId",
      description: "This endpoint retrieves the chat history for a specific session.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/chat-history/ghi789 \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/chat-history/ghi789',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "chatMessages": [
    {
      "message": {"text": "What is the current price of Bitcoin?"},
      "is_user": 1,
      "timestamp": "2023-07-28T12:34:56.789Z"
    },
    {
      "message": {"text": "The current price of Bitcoin is $50,000."},
      "is_user": 0,
      "timestamp": "2023-07-28T12:35:01.234Z"
    }
  ],
  "sessionInfo": {
    "session_id": "ghi789",
    "title": "Bitcoin Price Inquiry",
    "last_activity": "2023-07-28T12:35:01.234Z",
    "is_active": 1
  }
}`
    },
    {
      title: "35. Delete Chat History",
      method: "DELETE",
      url: "/api/api/chat-history/:sessionId",
      description: "This endpoint deletes the chat history for a specific session.",
      curlExample: `curl -X DELETE https://rad.huddlz.xyz/api/api/chat-history/ghi789 \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'delete',
  url: 'https://rad.huddlz.xyz/api/api/chat-history/ghi789',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "message": "Chat history deleted successfully."
}`
    },
    {
      title: "36. Search Chat History",
      method: "GET",
      url: "/api/api/search-chat-history",
      description: "This endpoint searches through the user's chat history.",
      curlExample: `curl -X GET "https://rad.huddlz.xyz/api/api/search-chat-history?query=bitcoin" \\
-H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://rad.huddlz.xyz/api/api/search-chat-history?query=bitcoin',
  headers: { 'x-api-key': 'your-api-key-here' }
};

axios(config)
  .then(response => console.log(JSON.stringify(response.data)))
  .catch(error => console.log(error));`,
      responseExample: `{
  "results": [
    {
      "session_id": "abc123",
      "title": "Bitcoin Analysis",
      "last_activity": "2023-07-28T12:34:56.789Z",
      "is_active": 1,
      "last_message": {"text": "The current price of Bitcoin is $50,000."}
    },
    {
      "session_id": "def456",
      "title": "Cryptocurrency Comparison",
      "last_activity": "2023-07-29T10:11:12.131Z",
      "is_active": 1,
      "last_message": {"text": "Bitcoin has shown better performance than Ethereum this month."}
    }
  ]
}`
    },
    {
      title: "37. Perform Search",
      method: "POST",
      url: "/api/api/search",
      description: "This endpoint allows users to perform a search query using the Maximo AI and Bing search integration. The search result is personalized based on the user's memories.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/search \\
        -H "x-api-key: your-api-key-here" \\
        -H "Content-Type: application/json" \\
        -d '{
          "query": "What is the current price of Bitcoin?"
        }'`,
      nodeExample: `const axios = require('axios');
        
        const data = {
          query: "What is the current price of Bitcoin?"
        };
        
        const config = {
          method: 'post',
          url: 'https://rad.huddlz.xyz/api/api/search',
          headers: { 
            'x-api-key': 'your-api-key-here',
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(data)
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
          "response": "The current price of Bitcoin is $50,000.",
          "images": ["https://example.com/image1.jpg", "https://example.com/image2.jpg"],
          "citations": [
            {
              "title": "Bitcoin Price Analysis",
              "url": "https://example.com/bitcoin-price"
            }
          ]
        }`
    },
    {
      title: "38. Get Search History",
      method: "GET",
      url: "/api/api/search-history/:userId",
      description: "This endpoint retrieves the search history for a specific user.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/search-history/{userId} \\
        -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
        
        const config = {
          method: 'get',
          url: 'https://rad.huddlz.xyz/api/api/search-history/{userId}',
          headers: { 'x-api-key': 'your-api-key-here' }
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `[
          {
            "id": 1,
            "query": "What is the price of Bitcoin?",
            "response": {
              "response": "The current price of Bitcoin is $50,000.",
              "images": ["https://example.com/image1.jpg", "https://example.com/image2.jpg"],
              "citations": [
                {
                  "title": "Bitcoin Price Analysis",
                  "url": "https://example.com/bitcoin-price"
                }
              ]
            },
            "timestamp": "2024-08-01T12:00:00Z"
          },
          // More history...
        ]`
    },
    {
      title: "39. Add User Memory",
      method: "POST",
      url: "/api/api/add-memory",
      description: "This endpoint allows users to add a memory to their personalized data.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/add-memory \\
        -H "x-api-key: your-api-key-here" \\
        -H "Content-Type: application/json" \\
        -d '{
          "memory": "Remember that I prefer trading during the Asian market hours."
        }'`,
      nodeExample: `const axios = require('axios');
        
        const data = {
          memory: "Remember that I prefer trading during the Asian market hours."
        };
        
        const config = {
          method: 'post',
          url: 'https://rad.huddlz.xyz/api/api/add-memory',
          headers: { 
            'x-api-key': 'your-api-key-here',
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(data)
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
          "message": "Memory added successfully",
          "processedMemory": "User prefers trading during the Asian market hours."
        }`
    },
    {
      title: "40. Get User Memories",
      method: "GET",
      url: "/api/api/get-memories/:userId",
      description: "This endpoint retrieves the memories associated with a specific user.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/get-memories/{userId} \\
        -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
        
        const config = {
          method: 'get',
          url: 'https://rad.huddlz.xyz/api/api/get-memories/{userId}',
          headers: { 'x-api-key': 'your-api-key-here' }
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `[
          {
            "id": 1,
            "memory": "User prefers trading during the Asian market hours.",
            "timestamp": "2024-08-01T12:00:00Z"
          },
          // More memories...
        ]`
    },
    {
      title: "41. Delete User Memory",
      method: "DELETE",
      url: "/api/api/delete-memory/:id",
      description: "This endpoint deletes a specific memory for the authenticated user.",
      curlExample: `curl -X DELETE https://rad.huddlz.xyz/api/api/delete-memory/{id} \\
        -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
        
        const config = {
          method: 'delete',
          url: 'https://rad.huddlz.xyz/api/api/delete-memory/{id}',
          headers: { 'x-api-key': 'your-api-key-here' }
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
          "message": "Memory deleted successfully"
        }`
    },
    {
      title: "42. Get All Existing Minds",
      method: "GET",
      url: "/api/api/all-minds",
      description: "This endpoint retrieves all existing minds. Each mind includes details such as title, username, description, model, and usage count.",
      curlExample: `curl -X GET https://rad.huddlz.xyz/api/api/all-minds \\
            -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
        
        const config = {
            method: 'get',
            url: 'https://rad.huddlz.xyz/api/api/all-minds',
            headers: { 
                'x-api-key': 'your-api-key-here'
            }
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
            "minds": [
                {
                    "id": 1,
                    "title": "Mind 1",
                    "username": "user1",
                    "description": "Description of mind 1",
                    "profile_pic_path": "/path/to/pic",
                    "model": "Maximo 1 Beta",
                    "plugin": "plugin-name",
                    "created_at": "2023-08-22T12:00:00Z",
                    "creator_username": "creator1",
                    "daily_message_cap": 100,
                    "credits_per_message": 1,
                    "usage_count": 50
                },
                // More minds...
            ]
        }`
    },
    {
      title: "43. Search Minds",
      method: "GET",
      url: "/api/api/search-minds",
      description: "This endpoint allows users to search for minds by title, username, or description.",
      curlExample: `curl -X GET "https://rad.huddlz.xyz/api/api/search-minds?query=AI" \\
            -H "x-api-key: your-api-key-here"`,
      nodeExample: `const axios = require('axios');
        
        const config = {
            method: 'get',
            url: 'https://rad.huddlz.xyz/api/api/search-minds?query=AI',
            headers: { 
                'x-api-key': 'your-api-key-here'
            }
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
            "minds": [
                {
                    "id": 1,
                    "title": "Mind AI",
                    "username": "ai_user",
                    "description": "Description of AI mind",
                    "profile_pic_path": "/path/to/pic",
                    "model": "Maximo 1 Beta",
                    "plugin": "plugin-name",
                    "created_at": "2023-08-22T12:00:00Z",
                    "creator_username": "creator_ai",
                    "daily_message_cap": 100,
                    "credits_per_message": 1,
                    "usage_count": 25
                },
                // More minds...
            ]
        }`
    },
    {
      title: "44. Use a Specific Mind",
      method: "POST",
      url: "/api/api/use-mind/:username",
      description: "This endpoint allows users to use a specific mind by providing the username. The user's message will be processed by the mind.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api/use-mind/ai_user \\
            -H "x-api-key: your-api-key-here" \\
            -H "Content-Type: application/json" \\
            -d '{"message": "Hello, mind!"}'`,
      nodeExample: `const axios = require('axios');
        
        const data = {
            message: "Hello, mind!"
        };
        
        const config = {
            method: 'post',
            url: 'https://rad.huddlz.xyz/api/api/use-mind/ai_user',
            headers: { 
                'x-api-key': 'your-api-key-here',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };
        
        axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
            "response": "Hi there! How can I assist you today?"
        }`
    },
    {
      title: "45. Ask Maximo Pandora (p1)",
      method: "POST",
      url: "/api/api-key-ask-maximo-p1",
      description: "This endpoint provides access to Maximo Pandora AI, a powerful language model capable of handling a wide range of tasks including general queries, analysis, writing, coding, math, and more. It also has specialized capabilities for cryptocurrency analysis. The model supports multiple languages and can process uploaded files for enhanced context.",
      curlExample: `curl -X POST https://rad.huddlz.xyz/api/api-key-ask-maximo-p1 \\
      -H "Content-Type: multipart/form-data" \\
      -H "x-api-key: your-api-key-here" \\
      -F "question=Can you help me write a professional email?" \\
      -F "language=en" \\
      -F "useSearch=true"`,
      nodeExample: `const axios = require('axios');
      const FormData = require('form-data');
      
      let data = new FormData();
      data.append('question', 'Can you help me write a professional email?');
      data.append('language', 'en');
      data.append('useSearch', 'true');
      
      // Optional: Add file if needed
      // data.append('file', fs.createReadStream('path/to/file'));
      
      const config = {
          method: 'post',
          url: 'https://rad.huddlz.xyz/api/api-key-ask-maximo-p1',
          headers: { 
              'x-api-key': 'your-api-key-here',
              ...data.getHeaders()
          },
          data: data
      };
      
      axios(config)
          .then(response => console.log(JSON.stringify(response.data)))
          .catch(error => console.log(error));`,
      responseExample: `{
          "message": {
              "text": "Here's a draft of a professional email for you:\n\nSubject: Project Update Meeting - Action Required\n\nDear [Name],\n\nI hope this email finds you well. I'm writing to schedule a project update meeting for next week to discuss our current progress and next steps.\n\nWould you be available either:\n- Tuesday, Oct 29 at 2:00 PM\n- Wednesday, Oct 30 at 10:00 AM\n\nThe meeting will last approximately 45 minutes, and we'll cover:\n- Current project status\n- Key milestones achieved\n- Upcoming deadlines\n- Resource allocation\n\nPlease let me know which time works best for you, and I'll send out a calendar invite accordingly.\n\nBest regards,\n[Your name]"
          },
          "thinking": ["Considering the components of a professional email",
                      "Including necessary details while maintaining brevity",
                      "Ensuring clear action items and next steps"],
          "steps": ["Created a clear subject line",
                    "Added a polite opening",
                    "Stated the purpose",
                    "Provided specific options",
                    "Listed agenda items",
                    "Included clear next steps",
                    "Added professional closing"],
          "modelUsed": "pandora-advanced",
          "sessionId": "abc123-def456"
      }`
    }
  ];

  const filteredEndpoints = endpoints.filter(endpoint =>
    endpoint.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    endpoint.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Container maxW="container.xl" py={10}>
        <VStack spacing={10} align="stretch">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading as="h1" size="2xl" mb={4}>
              Maximo AI PREMIUM API Documentation
            </Heading>
            <Text fontSize="xl" mb={4}>
              Explore our powerful API endpoints
            </Text>
            <Text fontSize="lg" mb={2}>
              Base URL: https://rad.huddlz.xyz/api
            </Text>
            <Text fontSize="lg" mb={8}>
              API Key: Use your personal API key (see "How to Get Your API Key" section below)
            </Text>
          </motion.div>

          <Flex justify="flex-end" mb={4}>
            <Button
              leftIcon={<SearchIcon />}
              onClick={() => setIsSearchVisible(!isSearchVisible)}
              variant="outline"
            >
              {isSearchVisible ? "Hide Search" : "Show Search"}
            </Button>
          </Flex>

          {isSearchVisible && (
            <Input
              placeholder="Search endpoints..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb={8}
              size="lg"
              bg={bgColor2}
              border="none"
              _focus={{
                boxShadow: "0 0 0 1px #63B3ED",
                borderColor: "blue.300",
              }}
            />
          )}

          {filteredEndpoints.map((endpoint, index) => (
            <Endpoint
              key={index}
              title={endpoint.title}
              method={endpoint.method}
              url={endpoint.url}
              description={endpoint.description}
              curlExample={endpoint.curlExample}
              nodeExample={endpoint.nodeExample}
              responseExample={endpoint.responseExample}
            />
          ))}

          <Box>
            <Heading as="h2" size="xl" mb={4}>
              How to Get Your API Key
            </Heading>
            <Text fontSize="lg" mb={4}>
              To get started with the Maximo AI API, you'll need to generate an API key. Follow these steps:
            </Text>
            <VStack align="start" spacing={4} pl={4}>
              <Text>1. Visit the Maximo AI website: Go to https://maximoai.co</Text>
              <Text>2. Sign up or log in to your account</Text>
              <Text>3. Access the Developer Page: Click on your profile picture, click on development and tools and select "Build"</Text>
              <Text>4. Create a new API key: Enter a name for your key and click "Create"</Text>
              <Text>5. View and manage your API keys on the developer page</Text>
              <Text>6. You can also get free points for making API requests on this page</Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default ApiDocumentation;