import React from 'react';
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import ApiDocumentation from './pages/ApiDocumentation';
import Authentication from './pages/Authentication';
import RateLimits from './pages/RateLimits';
// import theme from './theme';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box minH="100vh" bg="gray.50">
          <Navbar />
          <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            pt="70px"
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/endpoints" element={<ApiDocumentation />} />
              <Route path="/authentication" element={<Authentication />} />
              <Route path="/rate-limits" element={<RateLimits />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ChakraProvider>
  );
};

export default App;