import React, { useState } from 'react';
import { Box, Heading, Text, Flex, Button, useColorModeValue, Code, VStack, useClipboard } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaCopy } from 'react-icons/fa';

const CopyButton = ({ text }) => {
  const { hasCopied, onCopy } = useClipboard(text);

  return (
    <Button leftIcon={<FaCopy />} onClick={onCopy} size="sm" ml={2}>
      {hasCopied ? "Copied!" : "Copy"}
    </Button>
  );
};

const Endpoint = ({ title, method, url, description, curlExample, nodeExample, responseExample }) => {
  const [isOpen, setIsOpen] = useState(false);
  const bgColor = useColorModeValue('gray.700', 'gray.800');
  const borderColor = useColorModeValue('gray.600', 'gray.700');

  const getMethodColor = (method) => {
    switch (method) {
      case 'GET':
        return { text: 'green.300', bg: 'green.800' };
      case 'POST':
        return { text: 'blue.300', bg: 'blue.800' };
      case 'PUT':
        return { text: 'orange.300', bg: 'orange.800' };
      case 'DELETE':
        return { text: 'red.300', bg: 'red.800' };
      default:
        return { text: 'gray.300', bg: 'gray.800' };
    }
  };

  const methodColor = getMethodColor(method);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Box
        bg={bgColor}
        borderRadius="xl"
        borderWidth="1px"
        borderColor={borderColor}
        p={6}
        mb={8}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size="lg" color="white">{title}</Heading>
          <Text
            fontWeight="bold"
            color={methodColor.text}
            bg={methodColor.bg}
            px={3}
            py={1}
            borderRadius="full"
          >
            {method}
          </Text>
        </Flex>
        <Text fontSize="lg" mb={4} color="gray.300">{url}</Text>
        <Text mb={4} color="gray.400">{description}</Text>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          colorScheme="teal"
          variant="outline"
          mb={4}
        >
          {isOpen ? 'Hide Details' : 'Show Details'}
        </Button>
        {isOpen && (
          <VStack align="stretch" spacing={6}>
            <Box>
              <Flex alignItems="center" mb={2}>
                <Heading size="sm" color="white">cURL Example</Heading>
                <CopyButton text={curlExample} />
              </Flex>
              <Code p={3} borderRadius="md" bg="gray.800" color="green.300" whiteSpace="pre-wrap">
                {curlExample}
              </Code>
            </Box>
            <Box>
              <Flex alignItems="center" mb={2}>
                <Heading size="sm" color="white">Node.js Example</Heading>
                <CopyButton text={nodeExample} />
              </Flex>
              <Code p={3} borderRadius="md" bg="gray.800" color="blue.300" whiteSpace="pre-wrap">
                {nodeExample}
              </Code>
            </Box>
            <Box>
              <Heading size="sm" color="white" mb={2}>Response Example</Heading>
              <Code p={3} borderRadius="md" bg="gray.800" color="yellow.300" whiteSpace="pre-wrap">
                {responseExample}
              </Code>
            </Box>
          </VStack>
        )}
      </Box>
    </motion.div>
  );
};

export default Endpoint;