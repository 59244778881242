import React from 'react';
import { Box, Flex, Button, Image, Text, IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, VStack } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { HamburgerIcon } from '@chakra-ui/icons';
import maximoAiLogo from '../images/maximo-ai-logo-circle.png';

const Navbar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();

    const navItems = [
        { name: 'Home', path: '/' },
        { name: 'Endpoints', path: '/endpoints' },
        { name: 'Use Maximo AI', path: 'https://maximoai.co', external: true },
        { name: 'Maximo Search', path: 'https://search.maximoai.co', external: true },
    ];

    return (
        <Box
            as="nav"
            position="fixed"
            top="0"
            left="0"
            right="0"
            bg="gray.800"
            boxShadow="0 2px 4px rgba(0,0,0,0.2)"
            zIndex="999"
        >
            <Flex
                h="70px"
                alignItems="center"
                justifyContent="space-between"
                maxW="1200px"
                mx="auto"
                px={4}
            >
                <Flex alignItems="center">
                    <motion.div
                        whileHover={{ rotate: 360 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Image src={maximoAiLogo} h="40px" alt="Maximo AI Logo" />
                    </motion.div>
                    <Text
                        ml={3}
                        fontSize="xl"
                        fontWeight="bold"
                        fontFamily="'Lexend', sans-serif"
                        color="white"
                    >
                        Maximo AI
                    </Text>
                </Flex>

                {/* Desktop Navigation */}
                <Flex display={{ base: "none", md: "flex" }}>
                    {navItems.map((item, index) => (
                        <NavButton
                            key={index}
                            to={item.path}
                            isActive={location.pathname === item.path}
                            external={item.external}
                        >
                            {item.name}
                        </NavButton>
                    ))}
                </Flex>

                {/* Mobile Navigation */}
                <IconButton
                    aria-label="Open menu"
                    icon={<HamburgerIcon />}
                    display={{ base: "flex", md: "none" }}
                    onClick={onOpen}
                    variant="ghost"
                    size="lg"
                    color="white"
                />

                <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
                    <DrawerOverlay />
                    <DrawerContent bg="gray.800">
                        <DrawerCloseButton size="lg" color="white" />
                        <DrawerHeader fontFamily="'Lexend', sans-serif" fontSize="2xl" color="white">Menu</DrawerHeader>
                        <DrawerBody>
                            <VStack spacing={8} align="stretch" mt={8}>
                                <AnimatePresence>
                                    {navItems.map((item, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3, delay: index * 0.1 }}
                                        >
                                            <NavButton
                                                to={item.path}
                                                isActive={location.pathname === item.path}
                                                onClick={onClose}
                                                fontSize="xl"
                                                width="100%"
                                                justifyContent="flex-start"
                                                px={4}
                                                external={item.external}
                                            >
                                                {item.name}
                                            </NavButton>
                                        </motion.div>
                                    ))}
                                </AnimatePresence>
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Box>
    );
};

const NavButton = ({ to, children, isActive, onClick, external, ...props }) => {
    const ButtonComponent = external ? 'a' : Link;
    const buttonProps = external ? { href: to, target: "_blank", rel: "noopener noreferrer" } : { to };

    return (
        <Button
            as={ButtonComponent}
            {...buttonProps}
            variant="ghost"
            mx={2}
            my={1}
            position="relative"
            fontWeight={isActive ? "bold" : "medium"}
            color={isActive ? "teal.300" : "gray.300"}
            bg={isActive ? "rgba(129, 230, 217, 0.2)" : "transparent"}
            _hover={{ bg: 'rgba(129, 230, 217, 0.1)', color: 'white' }}
            _active={{ bg: 'rgba(129, 230, 217, 0.3)', color: 'white' }}
            borderRadius="full"
            onClick={onClick}
            fontFamily="'Roboto', sans-serif"
            transition="all 0.3s"
            overflow="hidden"
            {...props}
        >
            {children}
        </Button>
    );
};

export default Navbar;